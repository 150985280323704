import './assets/css/main.css'
import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'

const app = createApp(App)
const head = createHead()
const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

app.use(pinia)
app.use(router)
app.use(head)
app.provide('router', router);

Sentry.init({
  app,
  dsn: "https://560332343843ad8167f8f884e098e596@www.supertreetrunk.com//6",
  environment: import.meta.env.MODE,
})

app.mount('#app')