import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useFeedbackModalStore = defineStore('feedbackModal', () => {
  const feedbackModalOpen = ref(false)

  const openFeedbackModal = () => {
    feedbackModalOpen.value = true
  }

  const closeFeedbackModal = () => {
    feedbackModalOpen.value = false
  }

  return { feedbackModalOpen, openFeedbackModal, closeFeedbackModal }
})
