import { ref, watch, watchEffect } from 'vue'
import { defineStore } from 'pinia'
import LinkedinIcon from '@/assets/images/linkedin-login.svg'
import AppleIcon from '@/assets/images/apple-login.svg'
import GoogleIcon from '@/assets/images/google-login.svg'
import Cookies from 'universal-cookie'
import { useFeedbackModalStore } from '@/stores/feedbackModal'
import { useAppStateStore } from "@/stores/appState";


export const useUserLoginStore = defineStore('userLogin', () => {
  const cookies = new Cookies(document.cookie, { path: '/' })

  const popupOpen = ref(false)

  // expire in 1 day
  const expirationDateFromNow = (new Date().getTime() + 86400000).toString()
  const cookieIpui = ref<string>(cookies.get('ipui'))
  const ipui = cookieIpui.value ? cookieIpui.value.toString().replace(/&1$/, '') : ''
  const testIsIvUser = (cookieIpui: string) => /&1$/.test(cookieIpui)

  const isIvUser = ref(testIsIvUser(cookieIpui.value))

  const allowedHash = async (currentRouteHash: string) => {
    // 1. if no hash, set hash with expiration date, return true
    // 2. if no expiration date, add expiration date to current hash, continue
    // 3. if hash is the same, return true
    // 4. if hash has expired, set hash with expiration date, return true
    // 5. return false
    const uah = 'TP_UAH'
    const uahExpiry = 'TP_UAH_E'

    const userAllowedHash = localStorage.getItem(uah)
    const encodedCurrentHash = btoa(currentRouteHash)

    // 1
    if (userAllowedHash === null) {
      localStorage.setItem(uah, encodedCurrentHash)
      // set expired date to 1 day
      localStorage.setItem(uahExpiry, expirationDateFromNow)
      return true
    }

    // check expired, assumed expired if not found
    let storageExpiredDate = localStorage.getItem(uahExpiry)

    // 2
    if (storageExpiredDate === null) {
      // don't update hash
      localStorage.setItem(uahExpiry, expirationDateFromNow)
      storageExpiredDate = expirationDateFromNow
    }

    // 3
    if (localStorage.getItem(uah) === encodedCurrentHash) {
      localStorage.setItem(uahExpiry, expirationDateFromNow)
      return true
    }

    // 4
    if (new Date().getTime() > parseInt(storageExpiredDate)) {
      localStorage.setItem(uah, encodedCurrentHash)
      localStorage.setItem(uahExpiry, expirationDateFromNow)
      return true
    }

    // 5
    return false
  }

  const availableLoginMethods = [
    {
      name: 'LinkedIn',
      icon: LinkedinIcon,
      color: '#0077B5',
      textColor: '#FFFFFF',
      params: {
        type: 'linkedin-openidconnect',
      },
    },
    {
      name: 'Apple',
      icon: AppleIcon,
      color: '#000000',
      textColor: '#FFFFFF',
      params: {
        type: 'apple-id',
      },

    },
    {
      name: 'Google',
      icon: GoogleIcon,
      color: '#FFFFFF',
      borderColor: '#000000',
      textColor: '#000000',
      params: {
        type: 'google-oauth2',
      },
    },
  ]

  watch(() => document.cookie, () => {
    cookieIpui.value = cookies.get('ipui')
    isIvUser.value = testIsIvUser(cookieIpui.value)
  }, { immediate: true })

  const popupLogin = () => {
    if (cookieIpui.value) {
      return
    }
    popupOpen.value = true
    useAppStateStore().trackEvent(`teamplan:login:opened`)
  }

  const closePopup = () => {
    popupOpen.value = false
  }

  return { allowedHash, availableLoginMethods, popupOpen, popupLogin, closePopup, ipui, isIvUser }
})
